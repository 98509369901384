<template>
  <!-- 资料库>新增资料 -->
  <div
    class="dict"
    v-loading.fullscreen.lock="uploadDetailLoading"
  >
    <el-page-header
      style="margin-bottom: 1.04167vw"
      @back="goBack"
      content="目标列表 / 更新进度"
    >
      <template slot="title">
        <i class="el-icon-arrow-left"></i>返回
      </template>
    </el-page-header>
    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="ruleForm"
      :label-width="labelWidth"
    >
      <el-form-item
        label="目标类型："
        prop="name"
        v-if="ruleForm.targetType"
      >
        <el-input
          disabled
          v-model="ruleForm.targetType.label"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="目标名称："
        prop="name"
      >
        <el-input
          disabled
          v-model="ruleForm.targetName"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="目标值："
        prop="name"
      >
        <el-input
          disabled
          v-model="ruleForm.targetValue"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="计量单位："
        prop="name"
      >
        <el-input
          disabled
          v-model="ruleForm.targetUnit"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="截止日期："
        prop="name"
      >
        <el-input
          disabled
          v-model="ruleForm.endTime"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="已完成值："
        prop="name"
      >
        <el-input
          v-input-number
          min="0"
          @keyup.native="getInputChange(ruleForm.doneValue,'doneValue')"
          :disabled="!($route.query.type == 0) || isCheck"
          v-model="ruleForm.doneValue"
        ></el-input>
      </el-form-item>
      <el-form-item v-if="$route.query.type!=0 && !isCheck">
        <el-button
          type="primary"
          @click="editPark()"
        >新增</el-button>
      </el-form-item>
      <el-form-item v-if="$route.query.type == 0 && !isCheck">
        <el-button
          @click="goBack"
          class="reset"
        >返回</el-button>
        <el-button
          type="primary"
          :disabled="updataTargetLoading"
          :loading="updataTargetLoading"
          @click="updataTargetById('ruleForm')"
        >保存</el-button>
      </el-form-item>
    </el-form>

    <l-table
      v-if="targetType == 4"
      :columns="columns1"
      :dataSource="newList"
      :pagination="true"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="false"
      :checkBoxFixed="'left'"
      :style="{ width: 'calc(100% - ' + labelWidth + ')', 'padding-left': labelWidth }"
    >
      <el-table-column
        fixed="left"
        type="selection"
        width="55"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="园校名称"
        align="center"
        slot="name"
      ></el-table-column>
      <el-table-column
        label="操作"
        slot="handle"
        align="center"
        fixed="right"
      >
        <template slot-scope="scope">
          <div class="btns">
            <!-- style="color: #909399" -->
            <el-button
              size="mini"
              type="text"
              :disabled="scope.row.doneValue == 100"
              @click="editPark(scope.row.id,4)"
              v-if="!isCheck"
            ><span>编辑</span>
            </el-button>
            <el-popconfirm
              style="width:1.04167vw"
              title="确定删除吗？"
              class="popconfirm"
              @confirm="delPark(scope.row.id,4)"
            >
              <el-button
                slot="reference"
                size="small"
                type="text"
                :disabled="scope.row.doneValue == 100"
                v-if="!isCheck"
              >
                <!--  style="color: #909399" -->
                <span>删除</span>
              </el-button>
            </el-popconfirm>
            <el-button
              size="mini"
              type="text"
              @click="handleDetail(scope.row.id,4)"
            ><span>查看</span>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </l-table>
    <l-table
      v-if="targetType == 5"
      :columns="columns"
      :dataSource="tableData"
      :pagination="true"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="false"
      :checkBoxFixed="'left'"
      :style="{ width: 'calc(100% - ' + labelWidth + ')', 'padding-left': labelWidth }"
    >
      <el-table-column
        fixed="left"
        type="selection"
        width="55"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        label="园校名称"
        align="center"
        min-width="200"
        slot="name"
      ></el-table-column>
      <el-table-column
        prop="city"
        label="所在城市"
        align="center"
        min-width="200"
        slot="city"
      >
        <template
          slot-scope="scope">{{ scope.row.province }}{{ scope.row.city }}{{scope.row.county}}</template>
      </el-table-column>
      <el-table-column
        prop="buildingArea"
        label="建筑面积(m²)"
        align="center"
        min-width="130"
        slot="buildingArea"
      ></el-table-column>
      <el-table-column
        prop="outdoorArea"
        label="户外面积(m²)"
        align="center"
        min-width="130"
        slot="outdoorArea"
      ></el-table-column>
      <el-table-column
        prop="targetValue"
        label="班额"
        align="center"
        min-width="100"
        slot="targetValue"
      ></el-table-column>
      <el-table-column
        prop="startTime"
        label="开园时间"
        align="center"
        min-width="150"
        slot="startTime"
      ></el-table-column>
      <el-table-column
        prop="manageType"
        label="管理类型"
        align="center"
        min-width="100"
        slot="manageType"
      >
        <template
          slot-scope="scope"
          v-if="scope.row.manageType"
        >
          {{ scope.row.manageType.label }}
        </template>
      </el-table-column>
      <el-table-column
        prop="isSign"
        label="签约情况"
        align="center"
        min-width="100"
        slot="isSign"
      >
        <template
          slot-scope="scope"
          v-if="scope.row.isSign"
        >
          <span
            :class="scope.row.isSign.value == 0?'activeColor':''">{{ scope.row.isSign.label }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        slot="handle"
        align="center"
        min-width="150"
        fixed="right"
        v-if="!isCheck"
      >
        <template slot-scope="scope">
          <div class="btns">
            <el-button
              size="mini"
              type="text"
              :disabled="scope.row.isSign && scope.row.isSign.value == 1"
              @click="editPark(scope.row.id,5)"
            ><span>编辑</span>
            </el-button>
            <el-popconfirm
              style="width:1.04167vw"
              title="确定删除吗？"
              class="popconfirm"
              @confirm="delPark(scope.row.id,5)"
            >
              <el-button
                slot="reference"
                size="small"
                type="text"
                :disabled="scope.row.isSign && scope.row.isSign.value == 1"
              >
                <span>删除</span>
              </el-button>
            </el-popconfirm>
            <!-- <el-button size="small" type="text" @click="delPark(scope.row.id,5)"
              ><span style="color: #909399">删除</span>
            </el-button> -->
          </div>
        </template>
      </el-table-column>
    </l-table>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  data () {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      form: {
        name: "",
      },
      page: {
        num: 1,
        size: 10,
        total: 0,
      },
      tableData: [
        // {
        //   name: 1,
        // },
        // {
        //   label: "操作",
        //   slotName: "handle",
        // },
      ],
      loadingDetail: false,
      columns1: [
        {
          label: "园校名称",
          slotName: "name",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      columns: [
        {
          label: "园校名称",
          slotName: "name",
        },
        {
          label: "所在城市",
          slotName: "city",
        },
        {
          label: "建筑面积(m²)",
          slotName: "buildingArea",
        },

        {
          label: "户外面积(m²)",
          slotName: "outdoorArea",
        },

        {
          label: "班额",
          slotName: "targetValue",
        },

        {
          label: "开园时间",
          slotName: "startTime",
        },

        {
          label: "管理类型",
          slotName: "manageType",
        },

        {
          label: "签约情况",
          slotName: "isSign",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      tableLoading: false,
      uploadList: [],
      ruleForm: {},
      lookId: 0,
      targetType: 0,
      newList: [],
      expandList: [],
      delNewList: false,
      delExpandList: false,
      updataTargetLoading: false,
      uploadDetailLoading: false,
      isCheck: false,

      labelWidth: '120px',
    };
  },
  components: {
    LTable,
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      if (to.query.id) {
        vm.getManageInfo(to.query.id);
      }
    });
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler (val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler (val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created () {
    if (this.$route.query.isCheck == 1) {
      this.isCheck = this.$route.query.isCheck
    } else {
      this.isCheck = false
    }
    this.targetType = this.$route.query.type
    this.lookId = this.$route.query.lookId
    this.init()
    // 4是新园，5是拓展，0是updateType为0 
    if (this.targetType == 4) {
      this.initNewGardenList()
    } else if (this.targetType == 5) {
      this.initExpandGardenList()
    }
  },
  mounted () {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if (window.innerWidth < 1300) {
      this.labelWidth = '80px'
    } else {
      this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
    }
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    goBack () {
      this.$router.go(-1);
    },
    init () {
      let params = {
        id: this.lookId,
      };
      this.uploadDetailLoading = true
      this.$api.getTargetInfo(params).then((res) => {
        this.uploadDetailLoading = false;
        if (res.data.code == 0) {
          this.ruleForm = res.data.data;
          if (!this.ruleForm.doneValue) {
            this.ruleForm.doneValue = 0
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    initNewGardenList () {
      this.tableLoading = true
      this.$api.newGardenList({
        detailId: this.lookId,
        current: this.page.num,
        size: this.page.size,
      }).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.newList = res.data.data.records;
          this.page.total = res.data.data.total;
        } else {
          this.$message.error(res.data.msg);
          this.newList = []
          this.page.total = 0;
        }
      });
    },
    initExpandGardenList () {
      this.tableLoading = true
      this.$api.expandGardenList({
        detailId: this.lookId,
        current: this.page.num,
        size: this.page.size,
      }).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        } else {
          this.tableData = []
          this.$message.error(res.data.msg);
          this.page.total = 0;
        }
      });
    },
    //改变输入按键事件
    getInputChange (value, type) {
      let resultValue = value.replace(/[^\w\.\/]/ig, '')
      this.ruleForm[type] = resultValue
    },

    handleCurrentChange (val) {
      this.page.num = val;
      if (this.targetType == 4) {
        this.initNewGardenList()
      } else if (this.targetType == 5) {
        this.initExpandGardenList()
      }
    },
    handleSizeChange (val) {
      this.page.size = val;
      this.page.num = 1;
      if (this.targetType == 4) {
        this.initNewGardenList()
      } else if (this.targetType == 5) {
        this.initExpandGardenList()
      }
    },
    delPark (id, type) {
      if (type == 4) {
        this.$api.newGardenRemove(id).then((res) => {
          // this.tableLoading = false;
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.initNewGardenList()
            this.init()
          } else if (res.data.code == 3) {
            this.initNewGardenList()
            this.init()
            this.$message.error(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else if (type == 5) {
        this.$api.expandGardenRemove(id).then((res) => {
          // this.tableLoading = false;
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            // this.page.num = 1
            this.initExpandGardenList()
            this.init()
          } else if (res.data.code == 3) {
            this.initExpandGardenList()
            this.init()
            this.$message.error(res.data.msg);
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    editPark (id, type) {
      // if(!id && this.targetType == '4' && this.ruleForm.targetValue && Number(this.ruleForm.targetValue) === this.newList.length){
      //   this.$message.error('添加的目标数量已经等于目标值，不可再添加');
      //   return
      // } else if(this.targetType == '5' && this.ruleForm.targetValue && Number(this.ruleForm.targetValue) === this.tableData.length){
      //   this.$message.error('添加的目标数量已经等于目标值，不可再添加');
      //   return
      // }
      this.$router.push({
        path: '/registrationPark',
        query: {
          id: id,
          type: this.$route.query.type,
          detailId: this.$route.query.lookId
        }
      })
      // if(type == 4){
      // }else if(type == 5){}
    },
    //查看
    handleDetail (id, row) {
      this.$router.push({
        path: '/registrationPark?detail=1',
        query: {
          id: id,
          type: this.$route.query.type,
          detailId: this.$route.query.lookId
        }
      })
    },
    updataTargetById () {
      if (this.ruleForm.doneValue == '') {
        this.$message.error('请输入当前进度');
        return
      }
      this.updataTargetLoading = true
      this.$api.updataTarget({
        id: this.$route.query.lookId,
        doneValue: this.ruleForm.doneValue
      }).then((res) => {
        // this.tableLoading = false;
        this.updataTargetLoading = false
        if (res.data.code == 0) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.goBack()
          // this.initExpandGardenList()
          // this.init()
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  beforeDestroy () {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    width: calc(100% - 120px);
    padding-left: 120px;
    height: calc(100% - 500px);
    /deep/.el-table {
      width: 78.5% !important;
    }
  }
}
/deep/.el-popper {
  font-size: 80px !important;
  .el-button--primary {
    // background: red !important;
    // width: auto !important;
    // height: auto !important;
  }
}
.btns {
  /deep/button {
    margin-left: 5px;
  }
}
</style>
